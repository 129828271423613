var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Description*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:250",
                    expression: "'required|max:250'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: { name: "Description", rows: "3" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Description"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Price*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min_value:0",
                    expression: "'required|min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Price" },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", _vm._n($$v))
                  },
                  expression: "form.price",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Price"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  reduce: (x) => x.code,
                  name: "Currency",
                  options: _vm.currencyOptions,
                },
                model: {
                  value: _vm.form.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "currency", $$v)
                  },
                  expression: "form.currency",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Period*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min_value:1|max_value:365",
                    expression: "'min_value:1|max_value:365'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  value: _vm.form.period,
                  min: "1",
                  max: "365",
                  type: "number",
                  name: "Period",
                },
                on: { input: _vm.setPeriod },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Period"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Unit*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                attrs: {
                  reduce: (x) => x.code,
                  name: "Unit",
                  options: _vm.unitOptions,
                },
                model: {
                  value: _vm.form.unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "unit", $$v)
                  },
                  expression: "form.unit",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Payment Provider Ref")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Provider Reference" },
                model: {
                  value: _vm.form.providerReference,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "providerReference", $$v)
                  },
                  expression: "form.providerReference",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Provider Reference"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Company Link")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                attrs: {
                  reduce: (x) => x.code,
                  name: "Company",
                  options: _vm.companyOptions,
                },
                model: {
                  value: _vm.form.companyId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyId", $$v)
                  },
                  expression: "form.companyId",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Reward Amount")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min_value:0",
                    expression: "'min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Reward Amount" },
                model: {
                  value: _vm.form.rewardAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardAmount", _vm._n($$v))
                  },
                  expression: "form.rewardAmount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Reward Amount"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Reward Recurring")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.rewardRecurring,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardRecurring", $$v)
                  },
                  expression: "form.rewardRecurring",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }